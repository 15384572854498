html, body {
    width: 100%;
    height: 100%;
    background: #000;
}

#root {
    width: 100%;
    height: 100%;
}

.page {
    max-width: 30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}